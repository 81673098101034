import React from 'react'
import { Outlet } from 'react-router-dom'

import Header from '../Header'
import LoginPopup from '../Popup/LoginPopup'

const MainLayout: React.FC<PropType> = () => {
  return (
    <>
      <Header />
      <main className={`h-[1px] min-h-screen pl-0 lg:pl-72 `}>
        <Outlet />
      </main>
      <LoginPopup />
    </>
  )
}

interface PropType {}

export default MainLayout
