import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'

import { AuthProvider } from './component/provider/AuthProvider'
import { PersistQueryClientProvider, persister, queryClient } from './config/reactQuery'
import router from './router'

function App() {
  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <AuthProvider>
        <RouterProvider router={router} fallbackElement={<></>} />
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </PersistQueryClientProvider>
  )
}

export default App
