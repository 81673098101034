import { ReactComponent as MenuSVG } from '@/asset/svg/menu.svg'
import useLinkTo from '@/hook/useLinkTo'
import useMediaBreakpoint from '@/hook/useMediaBreakpoint'
import useStatePopup from '@/hook/useStatePopup'
import { PopUpStore, PopupSelector } from '@/store/PopupStore'
import React, { useEffect } from 'react'
import Menu from './Menu'

const Header: React.FC<PropType> = () => {
  const { linkTo } = useLinkTo()
  const menuPopupVisible = useStatePopup(PopupSelector.menuPopupVisible)
  const { size, overSize } = useMediaBreakpoint()

  const handleAvatarClick = () => {
    linkTo('/my/')
  }

  const toggleMenu = () => {
    PopUpStore.setMenuPopupVisible(!menuPopupVisible)
  }
  useEffect(() => {
    if (overSize('lg')) {
      PopUpStore.setMenuPopupVisible(true)
    } else {
      PopUpStore.setMenuPopupVisible(false)
    }
  }, [size])

  return (
    <>
      {!menuPopupVisible && (
        <div className=" fixed top-6 z-50 flex w-full justify-between px-6">
          <MenuSVG onClick={toggleMenu} />
          <button onClick={toggleMenu}>
            <MenuSVG />
          </button>
        </div>
      )}

      <Menu isOpen={menuPopupVisible} onClose={toggleMenu} />
    </>
  )
}

interface PropType {}

export default React.memo(Header)
