import React from 'react'

interface props {}

const Line: React.FC<props> = ({}) => {
  return (
    <div className="my-4 flex items-center justify-center sm:my-8">
      <div className="flex-grow border-t border-[#c5c4c2]"></div>
      <span className="mx-4 text-[#c5c4c2]">Or</span>
      <div className="flex-grow border-t border-[#c5c4c2]"></div>
    </div>
  )
}

export default Line
