import { ReactComponent as AppleSVG } from '@/asset/svg/apple2.svg'
import { ReactComponent as FBSVG } from '@/asset/svg/fb2.svg'
import { ReactComponent as GoogleSVG } from '@/asset/svg/google2.svg'
import Button from '@/component/Button'
import Input from '@/component/Input'
import LinkEnhance from '@/component/LinkEnhance'
import { validateEmail } from '@/helper/validationUtils'
import { exsits } from '@/server/user'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Line from '../Line'
import Step from '../Step'

const channelList = [
  {
    img: <GoogleSVG fill="white" />,
    href: '/api/auth/google1'
  },
  {
    img: <FBSVG fill="white" />,
    href: '/api/auth/google2'
  },
  {
    img: <AppleSVG fill="white" />,
    href: '/api/auth/google3'
  }
]
interface props {
  onTypeChange: (type: string) => void
  email: string
  setEmail: (email: string) => void
}

const Exsits: React.FC<props> = ({ onTypeChange, email, setEmail }) => {
  const { t } = useTranslation('login')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({})

  const validate = () => {
    const newErrors: { [key: string]: string | undefined } = {}
    newErrors.email = validateEmail(email)
    setErrors(newErrors)
    return Object.values(newErrors).every(error => !error)
  }

  const handleSubmit = async () => {
    if (!validate()) return
    setLoading(true)
    const res = await exsits({
      username: email
    })
    if (res.data) {
      onTypeChange('registered')
    } else {
      // onTypeChange('registered')
      onTypeChange('register')
    }
    setLoading(false)
  }

  return (
    <div className="my-10 sm:my-14">
      <Step current={1} onTypeChange={onTypeChange} />
      <div className="mt-7 flex">
        {channelList.map(item => (
          <LinkEnhance href={item.href} className="mr-2" key={item.href}>
            {item.img}
          </LinkEnhance>
        ))}
      </div>

      <Line />

      <p className="text-sm text-[#c5c4c2]">{t('exsits.tip1')}</p>
      <p className="mb-10 text-sm text-[#c5c4c2]">{t('exsits.tip2')}</p>
      <Input
        label={t('inputLabel.email')}
        value={email}
        onChange={e => setEmail(e.target.value)}
        error={errors.email}
      />
      <div className=" flex w-full justify-end">
        <Button loading={loading} onClick={handleSubmit} className="mt-6 rounded-full px-8 py-2 sm:py-3">
          {t('btn.continue')}
        </Button>
      </div>
    </div>
  )
}

export default Exsits
