// 验证邮箱
export const validateEmail = (value: string): string | undefined => {
  if (!value) return 'required'
  if (!/\S+@\S+\.\S+/.test(value)) return 'emailRule'
  return undefined
}

// 验证密码
export const validatRequired = (value: string): string | undefined => {
  if (!value) return 'required'
  return undefined
}

// 验证密码
export const validatePassword = (value: string): string | undefined => {
  if (!value) return 'required'
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*[ '"\t\n\r\\\/|`~<>&%;:,*?]).{8,40}$/
  if (!passwordPattern.test(value)) return 'passwordRule'
  return undefined
}
