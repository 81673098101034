import Modal from '@/component/Modal'
import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import React, { useEffect } from 'react'
import Login from './Login'

const LoginPopup: React.FC<PropType> = () => {
  const popupVisible = useStatePopup(PopupSelector.loginPopupVisible)
  useEffect(() => {
    console.log(popupVisible)
  }, [popupVisible])
  const handleClose = () => {
    PopUpStore.setLoginPopupVisible(false)
  }

  return (
    <Modal visible={popupVisible} onClose={handleClose}>
      <Login onClose={handleClose} />
    </Modal>
  )
}

interface PropType {}

export default LoginPopup
