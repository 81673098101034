import React, { useEffect, useState } from 'react'
import Exsits from '../Exsits'
import Register from '../Register'
import Registered from '../Registered'
import Reset from '../Reset'
import Sign from '../Sign'

interface PropType {
  onClose?: () => void
}

const Login: React.FC<PropType> = ({ onClose }) => {
  const [type, setType] = useState('')
  const [email, setEmail] = useState('')
  const handleClose = () => {
    onClose?.()
    setType('sign')
  }
  useEffect(() => {
    setType('sign')
  }, [])

  return (
    <>
      {type === 'sign' && <Sign onTypeChange={setType} onClose={handleClose} />}
      {type === 'exsits' && <Exsits onTypeChange={setType} email={email} setEmail={setEmail} />}
      {type === 'register' && <Register onTypeChange={setType} email={email} setEmail={setEmail} />}
      {type === 'reset' && <Reset onTypeChange={setType} email={email} setEmail={setEmail} />}
      {type === 'registered' && <Registered onTypeChange={setType} />}
    </>
  )
}

interface PropType {}

export default Login
