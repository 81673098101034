import api from '@/config/api'
import { getBaseHost } from '@/helper'
import { LoginOptions } from '@/type/auth'
import { Result, ResultBoolean } from '@/type/common'
import { FeedbackOption, ResetOption, ResultTokenType, ResultUserType, UserType, optionsEmailCode } from './types'

const apiHost = getBaseHost(process.env.NODE_ENV)

export const exsits = async (options: optionsEmailCode): Promise<ResultBoolean> => {
  try {
    const response: ResultBoolean = await api.post(`${apiHost}/exsits`, options)
    return response
  } catch (error: any) {
    return error
  }
}

export const login = async (options: LoginOptions): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.post(`${apiHost}/login`, options)
    return response
  } catch (error: any) {
    return error
  }
}
export const getInfo = async (): Promise<ResultUserType> => {
  try {
    const response: ResultUserType = await api.get(`${apiHost}/getInfo`)
    return response
  } catch (error: any) {
    return error
  }
}

export const register = async (options: UserType): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.post(`${apiHost}/register`, options)
    return response
  } catch (error: any) {
    return error
  }
}

export const resetPwdByEmail = async (options: ResetOption): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.put(`${apiHost}/system/user/profile/resetPwdByEmail`, options)
    return response
  } catch (error: any) {
    return error
  }
}
// /portal/feedback post
export const feedback = async (options: FeedbackOption): Promise<Result> => {
  try {
    const response: Result = await api.post(`${apiHost}/portal/feedback`, options)
    return response
  } catch (error: any) {
    return error
  }
}

export const captchaEmail = async (email: string): Promise<ResultBoolean> => {
  try {
    const response: ResultBoolean = await api.get(`${apiHost}/captchaEmail`, {
      params: {
        email: email
      }
    })
    return response
  } catch (error: any) {
    return error
  }
}

// 修改用户 put /system/user/profile
export const updateProfile = async (options: {
  lastName: string
  firstName: string
  phonenumber: string
}): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.put(`${apiHost}/system/user/profile`, options)
    return response
  } catch (error: any) {
    return error
  }
}

// 修改密码 /system/user/profile/updatePwd
export const updatePwd = async (options: { password: string }): Promise<ResultTokenType> => {
  try {
    const response: ResultTokenType = await api.put(`${apiHost}/system/user/profile/updatePwd`, options)
    return response
  } catch (error: any) {
    return error
  }
}
