import { ReactComponent as HotSVG } from '@/asset/svg/hot.svg'
import { ReactComponent as RightSVG } from '@/asset/svg/right.svg'
import LinkEnhance from '@/component/LinkEnhance'
import type { NavItemType } from '@/type/common'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NavItem: React.FC<PropType> = ({ item, onClose, onShowMore }) => {
  const { t } = useTranslation()
  const { name, icon, url, active, children, isHot } = item
  return children ? (
    <div className="group/item w-full cursor-pointer" onClick={() => onShowMore && onShowMore(name)}>
      <div
        className={`mb-2 ml-7 flex h-8 w-48  leading-10 ${active ? ' text-[#f7931a]' : 'border-transparent text-white '}`}
      >
        <div className="text24 flex w-full items-center  text-nowrap font-medium leading-8 text-white transition-all  duration-150 ease-in-out group-hover:visible group-hover/item:translate-x-2 group-hover/item:opacity-70 lg:leading-10">
          {t(`menu.${name}`)}
          <RightSVG className=" ml-auto h-8 w-16" fill="#ffffff" />
        </div>
      </div>
    </div>
  ) : (
    <>
      <LinkEnhance className="group/item text-text-dark" href={url}>
        <div
          onClick={onClose}
          className={`mb-2 ml-7 flex h-auto w-full leading-10 ${active ? ' text-[#f7931a]' : 'border-transparent text-white '}`}
        >
          <div className="text24 flex whitespace-normal font-medium  transition-all duration-150  ease-in-out group-hover:visible group-hover/item:translate-x-2  group-hover/item:opacity-70 ">
            {t(`menu.${name}`)}
            {isHot && <HotSVG className="ml-4 h-7 w-6" />}
          </div>
        </div>
      </LinkEnhance>
    </>
  )
}

interface PropType {
  item: NavItemType
  onClose: () => void
  onShowMore?: (name: string) => void
}

export default NavItem
