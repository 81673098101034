import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Line from '../Line'

// 定义组件的props接口
interface StepProps {
  onTypeChange: (type: string) => void
}

// 提取按钮样式为复用组件
const ActionButton: FC<{ onClick: () => void; children: React.ReactNode }> = ({ onClick, children }) => (
  <button
    onClick={onClick}
    className="flex w-full items-center justify-center rounded-full border border-[#c5c4c2] py-2 text-white hover:brightness-95 sm:py-4"
  >
    {children}
  </button>
)

const textClass = 'mb-2 mt-10 text-center text-sm text-[#c5c4c2]'

const Registered: FC<StepProps> = ({ onTypeChange }) => {
  const { t } = useTranslation('login')
  return (
    <div className="my-10 sm:my-14">
      <h3 className="text-white">{t('registered.title')}</h3>
      <p className="mt-3 text-sm text-[#c5c4c2]/80">{t('registered.tip')}</p>
      <p className={textClass}>{t('btn.signInTip')}</p>
      <ActionButton onClick={() => onTypeChange('sign')}>{t('btn.signIn')}</ActionButton>
      <Line />
      <p className={textClass}>{t('btn.resetPasswordTip')}</p>
      <ActionButton onClick={() => onTypeChange('reset')}>{t('btn.resetPassword')}</ActionButton>
    </div>
  )
}

export default Registered
