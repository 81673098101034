import { ReactComponent as ErrorSVG } from '@/asset/svg/error.svg'
import { ReactComponent as InfoSVG } from '@/asset/svg/info.svg'
import { ReactComponent as SuccessSVG } from '@/asset/svg/success.svg'
import React from 'react'
import ReactDOM from 'react-dom'

interface MessageProps {
  message: string
  type: 'info' | 'success' | 'error'
}

const MessageItem: React.FC<MessageProps> = ({ message, type }) => {
  let icon = null

  switch (type) {
    case 'info':
      icon = <InfoSVG className="h-4 w-4" fill="#faad14" />
      break
    case 'success':
      icon = <SuccessSVG className="h-4 w-4" fill="#52c41a" />
      break
    case 'error':
      icon = <ErrorSVG className="h-4 w-4" fill="#d9363c" />
      break
  }

  return (
    <div className="fixed left-1/2 top-12 z-[9999] -translate-x-1/2 transform">
      <div className="animate-slideInDown ">
        <div className="flex items-center justify-center rounded-md bg-white px-3 py-2 shadow-md">
          {icon}
          <p className="ml-3 text-black">{message}</p>
        </div>
      </div>
    </div>
  )
}

let messageId = 0

export const Message = {
  show(type: 'info' | 'success' | 'error', msg: string) {
    const container = document.createElement('div')
    container.id = `message-container-${messageId++}`
    document.body.appendChild(container)

    ReactDOM.render(<MessageItem type={type} message={msg} />, container)

    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(container)
      document.body.removeChild(container)
    }, 3000) // 消息显示3秒后自动移除
  },
  info(msg: string) {
    this.show('info', msg)
  },
  success(msg: string) {
    this.show('success', msg)
  },
  error(msg: string) {
    this.show('error', msg)
  }
}
