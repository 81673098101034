import i18n, { i18nInit } from '@/i18n'
import ReactDOM from 'react-dom/client'
import './index.css'

import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// i18n初始化
i18nInit('en')
  .then(() => {
    document.documentElement.lang = i18n.language
    document.documentElement.dir = i18n.dir(i18n.language)

    console.log('i18n init success')
  })
  .catch(error => {
    console.error('i18n init error', error)
  })
root.render(
  // <React.StrictMode>
  <>
    <App />
    <div id="message"></div>
  </>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
