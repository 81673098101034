import EventBus from '@/config/EventBus'
import { useSyncExternalStore } from 'react'

import { EVENT_NAMESPACE } from '@/config/constant'
import { PopUpStore } from '../store/PopupStore'

const subscribe = (listener: () => void) => {
  EventBus.addEventListener(EVENT_NAMESPACE.POPUP, listener)

  return () => {
    EventBus.removeEventListener(EVENT_NAMESPACE.POPUP, listener)
  }
}

const useStatePopup = <T>(selector: (state: typeof PopUpStore) => T): T => {
  return useSyncExternalStore(subscribe, () => selector(PopUpStore))
}

export default useStatePopup
