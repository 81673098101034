import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const RedirectLayout: React.FC<PropType> = () => {
  const lang = 'en'
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`/${lang}/`, { replace: true })
  })

  return null
}

interface PropType {}

export default RedirectLayout
