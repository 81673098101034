import EventBus from '@/config/EventBus'
import { EVENT_NAMESPACE } from '@/config/constant'

// Store
export class PopUpStore {
  static menuPopupVisible: boolean = false
  static setMenuPopupVisible = (value: boolean) => {
    this.menuPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }

  static loginPopupVisible: boolean = false
  static setLoginPopupVisible = (value: boolean) => {
    this.loginPopupVisible = value
    EventBus.dispatch(EVENT_NAMESPACE.POPUP)
  }
}

// selector
export class PopupSelector {
  static menuPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.menuPopupVisible

  static loginPopupVisible: SelectorType<boolean> = (store: typeof PopUpStore) => store.loginPopupVisible
}

type SelectorType<T> = (store: typeof PopUpStore) => T
